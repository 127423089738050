import { FormattedMessage } from "react-intl";

import styles from "../assets/styles/pages/home.scss";

import SlotsImg from "../assets/images/drawings/slots.png";

import GameItem from "../components/items/game/game.item";

import { getCategories_req, getItems_req } from "../api/home.api";

import MainLayout from "../components/layouts/main/main.layout";
import { getDeviceData } from "../helpers/detector.helper";
import Image from "next/image";

export default function Home({ isMobile, categories, items }) {
  return (
    <>
      <MainLayout categories={categories}>
        <div id={"home-page"}>
          <div className="top_wrapper">
            <div className="top_wrapper_inner guide">
              <h1 className={"main_heading"}>
                <FormattedMessage id={"best_online_casino_heading"} />
              </h1>
              <div className="info_wrapper">
                <div className="info_side info_left_side">
                  <div className="border" />
                  <h2 className={"info_title"}>
                    <FormattedMessage id={"you_got_to_place"} />
                    <p className={"info_content"}>
                      <FormattedMessage id={"if_you_curios_about"} />
                    </p>
                  </h2>
                </div>
                <div className="info_side info_right_side">
                  <Image
                    src={SlotsImg}
                    alt="logo"
                    className="slots_img"
                    width="122"
                    height="84"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="content_wrapper guide">
            <h2 className={"section_title"}>
              <FormattedMessage id={"top_games"} />
            </h2>
            {items && items.length ? (
              <div className="list_wrapper">
                {items.map((item) => (
                  <GameItem key={item.id} item={item} isMobile={isMobile} />
                ))}
                {/*<SimpleBanner image={BannerImg} />*/}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </MainLayout>
      <style jsx>{styles}</style>
    </>
  );
}

Home.getInitialProps = async function ({ query, req }) {
  let categories = null;
  let items = null;

  const { isMobile } = getDeviceData(req);

  try {
    categories = await getCategories_req({});
  } catch (e) {
    console.log("Home.getInitialProps -> getCategories_req Error:", e);
  }

  if (categories && categories.length) {
    try {
      items = await getItems_req({}, categories[0].key);
    } catch (e) {
      console.log("Home.getInitialProps -> getItems_req Error:", e);
    }
  }

  return {
    isMobile,
    categories,
    items,
  };
};
